<template>
  <v-container fluid>
    <v-row class="px-4">
      <v-card-title class="text-h5">
              {{ $t("reports.userStatusReport") }}
      </v-card-title>
    </v-row>
    <v-row class="px-4">
      <v-col v-if="$permission('ManyCompanyReportGeneration')" cols="12" md="6">
        <v-card>
          <v-form v-model="valid" ref="generate">
            <v-card-title class="text-h5">
              {{ $t("statistics.summary") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <DatePicker v-model="from" :label="$t('archive.dateFrom')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <DatePicker v-model="to" :label="$t('archive.dateTo')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <label>{{ $t("statistics.format") }} </label>
                  <v-select v-model="format" :items="formats" item-text="name" item-value="id" outlined dense :rules="requiredRules"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" offset="9">
                  <v-btn depressed color="primary" block :disabled="loading" @click.stop="generateCollectiveActivityReport">{{ $t("statistics.generate") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
      <v-col v-if="$permission('SingleCompanyReportGeneration')" cols="12" md="6">
        <v-card>
          <v-form v-model="validIndividual" ref="generateIndividual">
            <v-card-title class="text-h5">
              {{ $t("statistics.individual") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <DatePicker v-model="dateFrom" :label="$t('archive.dateFrom')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <DatePicker v-model="dateTo" :label="$t('archive.dateTo')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <label>{{ $t("statistics.company") }}</label>
                  <v-autocomplete v-model="company" :items="companies" item-text="name" item-value="id" outlined dense :rules="requiredRules"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" offset="9">
                  <v-btn depressed color="primary" block :disabled="loading" @click="generateIndividualActivityReport">{{ $t("statistics.generate") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-card-title class="text-h5">
              {{ $t("reports.userActivityReport") }}
      </v-card-title>
    </v-row>
    <v-row class="px-4">
      <v-col v-if="$permission('ManyCompanyReportGeneration')" cols="12" md="6">
        <v-card>
          <v-form v-model="stateValid" ref="generateState">
            <v-card-title class="text-h5">
              {{ $t("statistics.summary") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <DatePicker v-model="stateFrom" :label="$t('archive.dateFrom')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <DatePicker v-model="stateTo" :label="$t('archive.dateTo')" :rules="requiredRules" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" offset="9">
                  <v-btn depressed color="primary" block :disabled="loading" @click.stop="generateCollectiveStatusReport">{{ $t("statistics.generate") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
      <v-col v-if="$permission('SingleCompanyReportGeneration')" cols="12" md="6">
        <v-card>
          <v-form v-model="stateValidIndividual" ref="generateIndividualState">
            <v-card-title class="text-h5">
              {{ $t("statistics.individual") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <DatePicker v-model="stateDateFrom" :label="$t('archive.dateFrom')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <DatePicker v-model="stateDateTo" :label="$t('archive.dateTo')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <label>{{ $t("statistics.company") }}</label>
                  <v-autocomplete v-model="statusCompany" :items="companies" item-text="name" item-value="id" outlined dense :rules="requiredRules"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" offset="9">
                  <v-btn depressed color="primary" block :disabled="loading" @click="generateIndividualStatusReport">{{ $t("statistics.generate") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-card-title v-if="$store.getters.isAdmin"  class="text-h5">
              {{ $t("reports.tokenRequestReport") }}
      </v-card-title>
    </v-row>
    <v-row class="px-4">
      <v-col v-if="$store.getters.isAdmin" cols="12" md="6">
        <v-card>
          <v-form v-model="stateValid" ref="ApiReport">
            <v-card-title class="text-h5">
              {{ $t("statistics.summary") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <DatePicker v-model="stateFrom" :label="$t('archive.dateFrom')" :rules="requiredRules" />
                </v-col>
                <v-col cols="4">
                  <DatePicker v-model="stateTo" :label="$t('archive.dateTo')" :rules="requiredRules" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" offset="9">
                  <v-btn depressed color="primary" block :disabled="loading" @click.stop="generateApiReport">{{ $t("statistics.generate") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Services from '@/services'
import DatePicker from '@/components/forms/DatePicker'
import { downloadFile } from '@/utils/helpers'
export default {
  name: 'CompanyReports',
  components: {
    DatePicker
  },
  data () {
    return {
      valid: true,
      validIndividual: true,
      stateValid: true,
      stateValidIndividual: true,
      loading: false,
      from: null,
      to: null,
      stateFrom: null,
      stateTo: null,
      dateFrom: null,
      dateTo: null,
      stateDateFrom: null,
      stateDateTo: null,
      companies: null,
      company: null,
      statusCompany: null,
      formats: [
        { name: 'PDF', id: '1' },
        { name: 'CSV', id: '2' }
      ],
      format: null,
      requiredRules: [
        (v) => (v && v.length > 0) || this.$t('validation.fieldRequired')
      ]
    }
  },
  async created () {
    await this.getCompanies()
  },
  methods: {
    async getCompanies () {
      try {
        const { data } = await Services.get('/Company/GetCompanyNamesForReport')
        this.companies = data
      } catch (e) {}
    },
    async generateCollectiveActivityReport () {
      this.loading = true
      this.$refs.generate.validate()

      if (!this.valid) {
        this.loading = false
        return
      }

      try {
        var response = await Services.post('CompanyReport/GenerateCollectiveActivityReport', {
          from: this.from,
          to: this.to,
          fromat: parseInt(this.format)
        })
        await this.getExportedFile(response.data)

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.$refs.generate.resetValidation()
      } catch (e) {}
      this.loading = false
    },
    async getExportedFile (id) {
      try {
        const { data } = await Services.getFile(`/File/GetGeneratedFileByName?fileName=${id}`)
        downloadFile({ name: id }, data)
      } catch (e) {}
    },
    async generateIndividualActivityReport () {
      this.loading = true
      this.$refs.generateIndividual.validate()

      if (!this.validIndividual) {
        this.loading = false
        return
      }

      try {
        var response = await Services.post('CompanyReport/GenerateIndividualActivityReport', {
          companyId: this.company,
          from: this.dateFrom,
          to: this.dateTo
        })
        await this.getExportedFile(response.data)

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.$refs.generateIndividual.resetValidation()
      } catch (e) {}
      this.loading = false
    },
    async generateCollectiveStatusReport () {
      this.loading = true
      this.$refs.generateState.validate()

      if (!this.stateValid) {
        this.loading = false
        return
      }

      try {
        var response = await Services.post('CompanyReport/GenerateCollectiveStatusReport', {
          from: this.stateFrom,
          to: this.stateTo,
          fromat: parseInt(this.format)
        })
        await this.getExportedFile(response.data)

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.$refs.generate.resetValidation()
      } catch (e) {}
      this.loading = false
    },
    async generateApiReport () {
      this.loading = true
      this.$refs.generateState.validate()

      if (!this.stateValid) {
        this.loading = false
        return
      }

      try {
        var response = await Services.post('AppToken/GenerateReport', {
          from: this.stateFrom,
          to: this.stateTo,
          fromat: parseInt(this.format)
        })
        await this.getExportedFile(response.data)

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.$refs.generate.resetValidation()
      } catch (e) {}
      this.loading = false
    },
    async generateIndividualStatusReport () {
      this.loading = true
      this.$refs.generateIndividualState.validate()

      if (!this.stateValidIndividual) {
        this.loading = false
        return
      }

      try {
        var response = await Services.post('CompanyReport/GenerateIndividualStatusReport', {
          companyId: this.statusCompany,
          from: this.stateDateFrom,
          to: this.stateDateTo
        })
        await this.getExportedFile(response.data)

        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        this.$refs.generateIndividual.resetValidation()
      } catch (e) {}
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
